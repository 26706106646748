import React from "react"
import Layout from "../components/layout/layout"
import Button from "../components/Button/Button"
import * as SuccessStyles from "./success.module.css"

export default function Success() {
    return (
        <Layout>
            <div className={SuccessStyles.background}>
                <div className={SuccessStyles.container}>
                    <h1>Thank you for your message.</h1>
                    <p>Your message has been sent successfully and I will respond as soon as possible.</p>
                    <Button type="internal" colour="primary" to="/">Return to homepage</Button>
                </div>
            </div>
        </Layout>
    )
}